const TableSkeleton = () => {
    return (
        <>
            <table cellPadding="0" cellSpacing="0" className="table_loader ">
                <tbody>


                    <tr>
                        <th className="col1">
                            <span></span>
                        </th>
                        <th className="col4">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                        <th className="col5">
                            <span></span>
                        </th>
                    </tr>
                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>

                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>

                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>

                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>
                    <tr>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                        <td className="col1">
                            <span></span>
                        </td>
                        <td className="col4">
                            <span></span>
                            <span className="sub-temp"></span>
                            <span className="sub-temp sub-temp-three"></span>
                        </td>
                        <td className="col5">
                            <span></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default TableSkeleton;
